<template functional>
  <div class="product__description">
    <div class="small-title mb-3">{{ props.description ? 'Описание' : 'Описание данного товара отсутствует' }}</div>
    <div class="product__description-body">{{ props.description }}</div>
  </div>
</template>

<script>
export default {
  name: 'ProductDescription',
  props: {
    description: {
      type: String,
      required: true,
    },
  },
}
</script>
