<template>
  <div class="path">
    <div class="path">
      <div class="path__item">
        <router-link class="path__item-link" to="/">Главная</router-link>
        <span class="path__item-icon"> > </span>
      </div>
      <div v-for="(item, idx) in path" :key="item.name" class="path__item">
        <router-link class="path__item-link" :to="item.to"> {{ item.name }} </router-link>
        <span v-if="idx !== path.length - 1" class="path__item-icon"> > </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryPath',
  props: {
    path: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
