<template>
  <section class="product">
    <CategoryPath :path="categoryPath" />
    <div class="product-info mt-4">
      <b-row>
        <b-col md="8">
          <div class="top-slides">
            <VueSlickCarousel v-if="slides && slides.length" v-bind="settings">
              <div
                v-for="slide in slides"
                :key="slide.id"
                class="top-slides__item"
                :style="{ backgroundImage: `url(${slide.path})` }"
              ></div>
            </VueSlickCarousel>
            <div v-else class="top-slides__item">
              <img src="@/assets/images/no-photo.jpeg" alt="No photo" />
            </div>
          </div>

          <div class="d-md-none d-sm-block d-xs-block">
            <h4 class="product__title">
              {{ product.name || '' }} <br />
              <span class="product__price"> {{ product.price ? `${product.price} ₸` : '' }}</span>
            </h4>
            <p class="product__description">
              {{ product.short_description }}
            </p>
            <div v-if="product.attributes && product.attributes.length" class="product__attribute-wrapper">
              <div v-for="item in attributes" :key="item.id" class="product__attribute">
                <div class="product__attribute-title">{{ item.attribute }}</div>
                <div v-if="item.value && item.value.length" class="product__attribute-item-wrapper">
                  <span
                    v-for="attr in item.value"
                    :key="attr.name"
                    @click="pickAttribute(item.id, attr.name)"
                    class="product__attribute-item"
                    :class="{ 'product__attribute-item_active': attr.isPicked }"
                    >{{ attr.name }}</span
                  >
                </div>
              </div>
            </div>
            <template v-if="!isLoading">
              <template v-if="!isProductLeftInStock">
                <div class="product__left-info mt-3 mb-2">Данный товар отсутствует на складе</div>
                <button v-if="isLogged" @click="addToFavorites" class="w-100 button product__favorite-button">
                  Добавить в избранное
                </button>
              </template>
              <div v-else class="product__favorite">
                <button v-if="isLogged" @click="addToFavorites" class="button product__favorite-button">
                  Добавить в избранное
                </button>
                <button v-if="isLogged" @click="addToBasket" class="button product__favorite-button">
                  Добавить в корзину
                </button>
                <div v-if="!isLogged" class="product__left-info text-danger mt-2 mb-2">
                  Авторизуйтесь для добавления в корзину
                </div>
              </div>
            </template>
          </div>

          <div class="product-info__tab">
            <div
              v-for="tab in tabs"
              :key="tab.name"
              @click="setActiveTab(tab.name)"
              class="product-info__tab-item"
              :class="{ 'product-info__tab-item_active': tab.isActive }"
            >
              {{ tab.name }}
            </div>
          </div>
          <component
            :is="activeTab"
            :description="product.description || ''"
            :ratings="product.ratings"
            :productId="product.id"
            @addRating="addRating"
          ></component>
        </b-col>
        <b-col class="d-md-block d-sm-none d-none">
          <h4 class="product__title">
            {{ product.name || '' }} <br />
            <span class="product__price"> {{ product.price ? `${product.price} ₸` : '' }}</span>
          </h4>
          <p class="product__description">
            {{ product.short_description }}
          </p>
          <div v-if="product.attributes && product.attributes.length" class="product__attribute-wrapper">
            <div v-for="item in attributes" :key="item.id" class="product__attribute">
              <div class="product__attribute-title">{{ item.attribute }}</div>
              <div v-if="item.value && item.value.length" class="product__attribute-item-wrapper">
                <span
                  v-for="attr in item.value"
                  :key="attr.name"
                  @click="pickAttribute(item.id, attr.name)"
                  class="product__attribute-item"
                  :class="{ 'product__attribute-item_active': attr.isPicked }"
                  >{{ attr.name }}</span
                >
              </div>
            </div>
          </div>
          <template v-if="!isLoading">
            <template v-if="!isProductLeftInStock">
              <div class="product__left-info mt-3 mb-2">Данный товар отсутствует на складе</div>
              <button v-if="isLogged" @click="addToFavorites" class="w-100 button product__favorite-button">
                Добавить в избранное
              </button>
            </template>
            <div v-else class="product__favorite">
              <button v-if="isLogged" @click="addToFavorites" class="button product__favorite-button">
                Добавить в избранное
              </button>
              <button v-if="isLogged" @click="addToBasket" class="button product__favorite-button">
                Добавить в корзину
              </button>
              <div v-if="!isLogged" class="product__left-info text-danger mt-2 mb-2">
                Авторизуйтесь для добавления в корзину
              </div>
            </div>
          </template>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col md="8"> </b-col>
      </b-row>
      <div v-if="similarProductsFirstLine.length" class="similar-products mt-5">
        <div class="small-title mb-3">Похожие товары</div>
        <div class="block">
          <div class="block__body">
            <VueSlickCarousel v-bind="responseCarouselSetting" ref="similarProductsCarousel">
              <ProductCard v-for="product in similarProductsFirstLine" :key="product.id" :product="product" />
            </VueSlickCarousel>
            <span @click="moveCarousel('prev')" class="block__prev"><ArrowIcon direction="left" /></span>
            <span @click="moveCarousel('next')" class="block__next"><ArrowIcon direction="right" /></span>
          </div>
        </div>
        <div v-if="similarProductsSecondLine.length" class="block mt-3">
          <div class="block__body">
            <VueSlickCarousel v-bind="responseCarouselSetting" ref="similarProductsCarouselTwo">
              <ProductCard v-for="product in similarProductsSecondLine" :key="product.id" :product="product" />
            </VueSlickCarousel>
            <span @click="moveSecondCarousel('prev')" class="block__prev"><ArrowIcon direction="left" /></span>
            <span @click="moveSecondCarousel('next')" class="block__next"><ArrowIcon direction="right" /></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CategoryPath from '@/components/CategoryPath'
import VueSlickCarousel from 'vue-slick-carousel'
import Api from '@/services/api'
import ProductDescription from '@/views/product/components/ProductDescription'
import ProductRating from '@/views/product/components/ProductRating'
import ProductCard from '@/components/Product/ProductCard'
import ArrowIcon from '@/components/ArrowIcon'

export default {
  name: 'ProductPage',
  components: {
    CategoryPath,
    VueSlickCarousel,
    ProductDescription,
    ProductRating,
    ProductCard,
    ArrowIcon,
  },

  data: () => ({
    product: {},
    isSlidesLoading: false,
    attributes: [],
    tabs: [
      { component: 'product-description', name: 'О товаре', isActive: true },
      { component: 'product-rating', name: 'Отзывы', isActive: false },
    ],
    similarProducts: [[], []],
    leftover: null,
    settings: {
      dots: true,
      dotsClass: 'slick-dots custom-dot-class',
      edgeFriction: 0.35,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      // autoplay: true,
      // autoplaySpeed: 4000,
    },
    responseCarouselSetting: {
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      swipe: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),

  computed: {
    slides() {
      if (!this.product.id) return []
      if (!this.product.images || !this.product.images.length) return []
      return this.product.images
      // return []
    },

    cartProducts() {
      return this.$store.state.products.basket
    },

    favoriteProducts() {
      return this.$store.state.products.favorites
    },

    activeTab() {
      const active = this.tabs.find((el) => el.isActive)
      return active.component
    },

    categoryPath() {
      if (this.product && this.product.categories?.length) {
        const category = this.product.categories[0]
        if (category) {
          return [{ name: category.name, to: { name: 'layout.category', params: { slug: category.slug, page: 1 } } }]
        }
      }
      return []
    },

    similarProductsFirstLine() {
      const hitProducts = this.similarProducts
      if (!hitProducts.length) return []
      return hitProducts[0]
    },

    similarProductsSecondLine() {
      const hitProducts = this.similarProducts
      if (!hitProducts.length) return []
      return hitProducts[1]
    },

    isProductLeftInStock() {
      return !!(this.leftover && this.leftover.id && this.leftover.quantity)
    },

    isLogged() {
      const token = this.$store.state.token
      if (token) return true
      const lsToken = localStorage.getItem('uni_token')
      return !!lsToken
    },
  },

  watch: {
    async $route() {
      await this.fetchProductData()
    },
  },

  async mounted() {
    await this.fetchProductData()
  },

  methods: {
    async fetchProductData() {
      try {
        this.$store.commit('SET_LOADING', true)
        this.isSlidesLoading = true
        const { product } = await this.wrapAndHandleRequest(() => Api.fetchProduct(this.$route.params.slug), false)
        this.setProduct(product)
        this.$store.commit('SET_LOADING', false)
        await this.checkQuantity(false)
        const response = await this.wrapAndHandleRequest(() => Api.fetchSimilarProducts(product.slug), false)
        this.similarProducts = response.product
      } finally {
        setTimeout(() => (this.isSlidesLoading = false), 1000)
      }
    },

    setProduct(product) {
      this.product = product
      if (this.product.attributes && this.product.attributes.length) {
        this.product.attributes.forEach((el) => {
          if (el && el.value && el.value.length) {
            const attrs = el.value.map((attr, idx) => ({ name: attr, isPicked: idx === 0 }))
            this.attributes.push({ ...el, value: attrs })
          }
        })
      }
    },

    addRating(rating) {
      this.product.ratings.push(rating)
    },

    async checkQuantity(blockUi) {
      const productId = this.product.id
      const config = []
      this.attributes.forEach((el) => {
        const pickedValue = el.value.find((attr) => attr.isPicked)
        config.push({
          attribute_id: el.id,
          value: pickedValue.name,
        })
      })
      const { leftover } = await this.wrapAndHandleRequest(() =>
        Api.checkQuantity({ productId, config: JSON.stringify(config) }, blockUi),
      )
      this.leftover = leftover
    },

    async pickAttribute(id, attrName) {
      const attr = this.attributes.find((el) => el.id === id)
      attr.value.forEach((item) => (item.name === attrName ? (item.isPicked = true) : (item.isPicked = false)))
      await this.checkQuantity(true)
    },

    async addToFavorites() {
      if (!this.isLogged) {
        let favorites = JSON.parse(localStorage.getItem('uni_favorites'))
        if (!favorites || !Array.isArray(favorites)) favorites = []
        if (favorites.find((el) => el.id === this.product.id)) {
          return this.makeToast('Избранные', 'Данный товар уже добавлен в избранные', 'warning', false)
        }

        favorites.push(this.product)
        localStorage.setItem('uni_favorites', JSON.stringify(favorites))
        return this.makeToast('Избранные', 'Товар был успешно добавлен в избранные', 'success', false)
      }

      if (this.favoriteProducts.find((el) => el.id === this.product.id)) {
        return this.makeToast('Избранные', 'Данный товар уже добавлен в избранные', 'warning', false)
      }

      const { favorite } = await this.wrapAndHandleRequest(() =>
        Api.saveToFavorite({ productId: this.product.id, status: 1 }),
      )
      this.$store.commit('products/ADD_PRODUCT_TO_FAVORITES', favorite.product_id)
      return this.makeToast('Избранные', 'Товар был успешно добавлен в избранные', 'success', false)
    },

    async addToBasket() {
      if (this.isLogged) {
        if (this.cartProducts.find((el) => el.leftover_id === this.leftover.id)) {
          return this.makeToast('Корзина', 'Данный товар уже добавлен в корзину', 'warning', false)
        }
        const cart = JSON.stringify([...this.cartProducts, { leftover_id: this.leftover.id, quantity: 1 }])
        await this.wrapAndHandleRequest(() => Api.updateCart({ cart }))
        const { cart: updatedCart } = await this.wrapAndHandleRequest(Api.fetchCartList, false)
        this.$store.commit('products/SET_BASKET_PRODUCTS', updatedCart)
        return this.makeToast('Корзина', 'Товар был успешно добавлен в корзину', 'success', false)
      }
      // let cart = JSON.parse(localStorage.getItem('uni_basket'))
      // if (!cart || !Array.isArray(cart)) cart = []
      // if (cart.find((el) => el.leftover_id === this.leftover.id)) {
      //   return this.makeToast('Корзина', 'Данный товар уже добавлен в корзину', 'warning', false)
      // }
      //
      // cart.push({ leftover_id: this.leftover.id, quantity: 1, product: this.product })
      // localStorage.setItem('uni_basket', JSON.stringify(cart))
      // this.makeToast('Корзина', 'Товар был успешно добавлен в корзину', 'success', false)
    },

    setActiveTab(name) {
      this.tabs.forEach((tab) => (tab.name === name ? (tab.isActive = true) : (tab.isActive = false)))
    },

    moveCarousel(direction) {
      direction === 'next' ? this.$refs.similarProductsCarousel.next() : this.$refs.similarProductsCarousel.prev()
    },

    moveSecondCarousel(direction) {
      direction === 'next' ? this.$refs.similarProductsCarouselTwo.next() : this.$refs.similarProductsCarouselTwo.prev()
    },
  },
}
</script>
