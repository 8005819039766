<template>
  <div class="product__rating">
    <div class="small-title">Отзывы</div>
    <div class="mt-3">
      <textarea
        class="product__rating-textarea"
        v-model.trim="comment"
        :placeholder="user ? 'Оставьте отзыв...' : 'Войдите, чтобы оставить отзыв'"
        :disabled="!user"
      ></textarea>
    </div>
    <div v-if="user" class="mt-1 d-flex justify-content-between align-items-center">
      <div class="stars">
        <svg
          v-for="star in 5"
          :key="star"
          @click="rating = star"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          :fill="Number(rating) >= star ? '#FFC144' : '#C4C4C4'"
          class="mr-1 cursor-pointer"
          viewBox="0 0 16 16"
        >
          <path
            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
          />
        </svg>
      </div>
      <b-button @click="postRating" variant="outline-secondary" :disabled="!isDataValid"> Оставить отзыв </b-button>
    </div>
    <div v-if="ratingList.length" class="product__rating-feedbacks mt-4">
      <div v-for="item in ratingList" :key="item.id" class="product__rating-feedback mb-4">
        <div class="product__rating-feedback-title">
          <span class="font-weight-bold"> {{ item.fullname }} </span>
          <span class="ml-3 product__rating-feedback-date">
            {{ item.created_at }}
          </span>
        </div>

        <div class="stars"><Rating :rating="String(item.value)" /></div>
        <div class="product__rating-feeedback-description mt-2">
          {{ item.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from '@/components/Rating'
import Api from '@/services/api'

export default {
  name: 'ProductRating',
  components: {
    Rating,
  },

  props: {
    ratings: {
      type: Array,
      required: true,
      default: () => [],
    },

    productId: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    rating: 0,
    comment: '',
    ratingList: [],
  }),

  computed: {
    user() {
      return this.$store.state.user
    },

    isDataValid() {
      return this.rating && this.comment.length
    },
  },

  methods: {
    async postRating() {
      if (!this.rating || !this.comment.length) return
      const body = {
        productId: this.productId,
        value: this.rating,
        comment: this.comment,
      }
      const { rating } = await this.wrapAndHandleRequest(() => Api.addRating(body))
      this.$emit('addRating', rating)
    },
  },

  mounted() {
    const list = JSON.parse(JSON.stringify(this.ratings)).reverse()
    this.ratingList = list
  },
}
</script>
